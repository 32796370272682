import AboutCompany from "../aboutCompany";
import Company from "../../about/company";

function companySection() {
  return (
    <>
      <AboutCompany />

      <Company />
    </>
  );
}

export default companySection;
